<template>
  <div class="view-home">
    <div class="container questions-wrap pt-5">
      <div class="questions-title">
        <h3 class="text-primary">{{ $t('completed.title') }}</h3>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-md-6">
          <div class="completed-bg"></div>
        </div>
        <div class="col-12 col-md-6 py-5 text-center">
          <h3 class="completed-title py-3">{{ $t('completed.completed-thanks') }}</h3>
          <p class="my-3">{{ $t('completed.completed-desc') }}</p>
          <a href="#" @click.prevent="goToCabinet" class="btn btn-primary">{{ $t('completed.go-to-cabinet') }}</a>
          <p class="my-3 text-danger">{{ $t('completed.call-center-title') }}</p>
          <a href="tel:87273100258" class="btn btn-light completed-link">
            <img src="/images/phone-icon.svg" class="mr-2">8 727 310 02 58
          </a>
          <div class="hint">{{ $t('completed.call-center-hint') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Completed",
  data() {
    return {
      project_id: null
    }
  },
  mounted() {
    // const { project_id } = JSON.parse(sessionStorage.getItem('quiz'))
    // this.project_id = project_id
    // if(sessionStorage.getItem('display_results')==0){
    //   window.location.href = this.project_id == 1 ?  `${window.CABINET_ROOT}` : `${window.QABILET_ROOT}`;
    // }
    // else
    // {
    //   window.location.href = this.project_id == 1 ? `${window.CABINET_ROOT}/testing` : `${window.QABILET_ROOT}/select-testing`
    // }
    localStorage.removeItem('avatar');
    localStorage.removeItem('avatar-base64');
    localStorage.removeItem('quiz');
    localStorage.removeItem('timer');
    localStorage.removeItem('quiz-token');
    localStorage.removeItem('results');
    localStorage.removeItem('saved-answers');
    localStorage.removeItem('identification');
  },
  methods: {
    goToCabinet(){
      if(localStorage.getItem('display_results')===1){
        window.location.href = this.project_id == 1 ? `${window.QABILET_ROOT}` : `${window.CABINET_ROOT}`
      }
      else{
        window.location.href = this.project_id == 1 ? `${window.QABILET_ROOT}/select-testing` : `${window.CABINET_ROOT}/testing`
      }
    }
  },
}
</script>

<style scoped>
</style>